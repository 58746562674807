.BoxPreview {
  --BoxPreview-padding-bottom: var(--md);
  --BoxPreview-width: none;

  cursor: default;
  max-width: var(--BoxPreview-width);
  overflow: hidden;
  position: relative;
  width: 100%;

  &-figure {
    isolation: isolate;
    margin: 0;
    padding-top: var(--sm);

    &_disabled {
      pointer-events: none;
      user-select: none;
    }
  }

  &-imgWrapper {
    background: var(--color-bg-page);
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius);
    padding: var(--md) var(--md) var(--BoxPreview-padding-bottom);
  }

  &-figure-header {
    color: var(--color-text-minimum);
    display: inline-block;
    font-size: 11px;
    font-weight: var(--font-weight-bold);
    line-height: 1;
    margin-bottom: var(--xs);
    text-transform: uppercase;
  }

  &-caption {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    margin-bottom: var(--sm);

    &-title {
      display: block;
      font-weight: var(--font-weight-bold);
    }
  }

  &-enterprise-description {
    flex: 1;
    padding-left: var(--md);
  }

  // Server Variables
  &_serverVariables {
    :global(.ServerVars-url-wrapper) {
      overflow: hidden;
    }
  }

  // Security Scheme
  &_securityScheme {
    &-box {
      background: var(--gray10);
      padding: var(--sm) var(--sm) 0;

      &-footer {
        padding: var(--sm) 0;
      }
    }

    &-input {
      font-family: var(--font-family-mono);
      font-size: 11px;
    }
  }

  // Custom Variables
  &_customVariables {
    &-cursor {
      align-items: center;
      display: flex;
      font-size: 14px;
    }

    &-cursor::after {
      animation: blink 1s step-start 0s infinite;
      background-color: black;
      content: '';
      display: inline-block;
      height: 1.2em;
      width: 1px;
    }

    @keyframes blink {
      50% {
        opacity: 0;
      }
    }

    :global {
      .Menu {
        margin-left: var(--xs);
        margin-top: var(--xs);
        overflow: clip;
        padding: 0;
      }

      .Menu-Item:first-child {
        background-color: var(--gray90);
      }

      .Menu-Item span:last-child {
        color: var(--gray50);
        font-weight: 600;
      }
    }
  }
}
