:global(.rm-Banners) {
  transition: transform var(--transition-slow) var(--transition-timing);
}

:global(.rm-Banners_hidden) {
  position: absolute;
  transform: translateY(100%);
  width: 100%;
}

.SuperHub {
  --SuperHub-aside-width: 360px;
  --SuperHub-background: var(--black);
  --SuperHub-border-radius: var(--sm);
  --SuperHub-margin: 15px;
  --SuperHub-top-nav-height: 0px;
  --SuperHub-controls-z-index: 3;
  --SuperHub-settings-z-index: 2;
  --Superhub-content-z-index: 1;
  --Superhub-overflow-height: 100%;
  --MyDevelopers-bottom-bar-height: 45px;
  --MyDevelopers-min-content-height: 300px;

  $root: &;

  display: flex;
  flex-direction: column;
  height: 100vh;

  // Overrides legacy hub layout when the superhub is active.
  // Allows the root container to have a flex layout.
  // @see packages/react/src/Hub/root.scss
  :global(.rm-Header + .rm-ReferenceMain) {
    min-height: unset;
  }

  // Styles for superhub admin interface.
  &_admin {
    --SuperHub-top-nav-height: 42px;

    // We can't wrap this component in shadow dom, so using !important as an
    // extra protection from user custom css
    background: var(--SuperHub-background) !important;
    inset: 0;
    position: absolute;
  }

  // Styles for the document.body to disable scrolling
  &_no-scroll {
    overflow: hidden;
  }

  &-project-controls {
    background: var(--SuperHub-background);
    height: var(--SuperHub-top-nav-height);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--SuperHub-controls-z-index);
  }

  // Wraps the content that consists of the user's "Hub"
  &-hub-wrapper {
    background: var(--color-bg-page);
    border-radius: var(--SuperHub-border-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    inset: var(--SuperHub-top-nav-height) 0 0;
    opacity: 1;
    overflow: clip;
    position: absolute;
    transform-origin: center center;
    transition-duration: var(--transition-slow);
    transition-property: inset, border-radius, transform, opacity;
    transition-timing-function: var(--transition-timing);
    z-index: var(--Superhub-content-z-index);

    &-overflow {    
      display: flex;
      flex-direction: column;
      height: var(--Superhub-overflow-height);
      overflow: auto;
    }

    // Edit mode
    #{$root}_edit & {
      border-radius: var(--SuperHub-border-radius);
      inset: var(--SuperHub-top-nav-height) var(--SuperHub-margin) var(--SuperHub-margin);
    }

    #{$root}_aside-standalone & {
      border-radius: var(--SuperHub-border-radius);
      inset: var(--SuperHub-margin) var(--SuperHub-aside-width) var(--SuperHub-margin) var(--SuperHub-margin);
    }

    #{$root}_aside & {
      border-radius: var(--SuperHub-border-radius);
      inset: var(--SuperHub-top-nav-height) var(--SuperHub-aside-width) var(--SuperHub-margin) var(--SuperHub-margin);
    }

    #{$root}_settings & {
      border-radius: var(--SuperHub-border-radius);
      inset: var(--SuperHub-top-nav-height) var(--SuperHub-margin) var(--SuperHub-margin);
      opacity: 0;
      transform: scale(0.98);
    }

    #{$root}_my-developers & {
      inset: var(--SuperHub-top-nav-height) var(--SuperHub-margin) var(--SuperHub-margin);
    }

    #{$root}_my-developers-expanded & {
      opacity: 0;
    }

    #{$root}_my-developers-collapsed & {
      // when my developers is in its collapsed state at the bottom of the
      // viewport, make the height of the main content only the remaining area
      // above my developer's content and bottom bar, rather than full height
      --Superhub-overflow-height: calc(100% - var(--MyDevelopers-min-content-height) - var(--MyDevelopers-bottom-bar-height));
    }

    #{$root}_settings-preview & {
      border-radius: var(--SuperHub-border-radius);
      inset: var(--SuperHub-top-nav-height) var(--SuperHub-aside-width) var(--SuperHub-margin) var(--SuperHub-margin);
    }

    #{$root}_versions & {
      border-radius: var(--SuperHub-border-radius);
      inset: var(--SuperHub-top-nav-height) var(--SuperHub-margin) var(--SuperHub-margin) var(--SuperHub-aside-width);
    }
  }

  // Account for the superhub top nav when showing the Recipes modal on a "view" mode
  // Guides page, Reference page, Custom Page, etc.
  :global(#tutorialmodal-root) {
    padding: 56px 30px 30px;
  }
}
