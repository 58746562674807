@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.GitConnection {
  &_loading,
  &-spinner {
    height: 100%;
  }

  &Setup {
    background: transparent;
    border-color: var(--color-border-default);

    &-alert {
      margin: var(--sm) 0 calc(var(--sm) * -1) 0;
    }

    &-list {
      counter-reset: item;
      display: flex;
      flex-direction: column;
      gap: var(--md);
      list-style: none;
      margin: 0;
      padding: var(--md) 0 var(--sm);

      &Item {
        column-gap: var(--sm);
        counter-increment: item;
        display: grid;
        grid-template-columns: auto 1fr;

        &::before {
          align-items: center;
          background-color: rgba(white, 0.15);
          border: 1px solid var(--color-border-default);
          border-radius: 100%;
          color: var(--color-text-default);
          content: counter(item);
          display: flex;
          font-size: 10px;
          font-weight: var(--font-weight);
          grid-column: 1;
          height: var(--md);
          justify-content: center;
          text-align: center;
          width: var(--md);
        }

        &-heading,
        &-content {
          color: var(--color-text-muted);
          font-size: 14px;
          grid-column: 2;
          margin: 0;
        }

        &-content {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          gap: var(--sm);

          :global(p) {
            margin: 0;
          }

          :global(a:not(.Button)) {
            color: var(--color-text-muted);
            text-decoration: underline;
            transition: color var(--transition-fast);

            &:hover {
              color: var(--color-text-default);
            }
          }
        }

        &-fieldset {
          margin-bottom: 15px;
          margin-top: var(--sm);
          max-width: 320px;
          width: 100%;
        }
      }
    }

    &-repoList {
      border: 1px solid var(--color-input-border);
      border-radius: var(--border-radius);
      display: flex;
      flex-direction: column;

      &-button {
        background: var(--color-input-background);
        border: 0;
        border-radius: 0;
        cursor: pointer;
        font-family: var(--font-family);
        height: $button-sm;
        padding: var(--xs) var(--sm);
        text-align: left;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.9;
        }

        &:hover,
        &:active,
        &:focus {
          &:not(:disabled) {
            background: var(--gray30);
          }
        }
      }
    }

    &-link {
      color: var(--color-text-default) !important;
      font-size: 14px;
      font-weight: var(--font-weight);
      padding: 0;
    }
  }

  &ChooseRepo {
    &-alert {
      padding: 0 0 var(--md) 0;
    }
  }

  &ConnectedRepo {
    &-repo-details {
      background: transparent;
      border-color: var(--color-border-default);

      &-link {
        color: var(--color-text-default) !important;
        font-size: 14px;
        font-weight: var(--font-weight);
        padding: 0;
      }

      &-last-sync,
      &-connected-by {
        color: var(--color-text-minimum);
        font-size: 12px;
        font-weight: var(--font-weight-normal);
      }
    }

    &-link {
      color: inherit;
      text-decoration: underline;
    }

    &_inactive {
      opacity: 0.4;
    }
  }

  // UI about needing an empty repo
  &-info {
    font-size: 12px;
    margin-top: var(--sm);

    &_well {
      background: rgba(white, 0.1);
      border: 1px solid rgba(white, 0.15);
      border-radius: var(--border-radius);
      padding: var(--sm);
    }
  }
}
