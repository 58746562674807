.Content {
  &-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    height: 100%;
    min-height: 0;
    overflow: hidden;
    width: 100%;
  }

  &-form-wrapper {
    height: 100%;
    min-height: 0;
  }
}
