@import '@core/styles/mixins/dark-mode';

.ProjectNavigation {
  &-container {
    align-items: center;
    display: grid;
    gap: var(--sm);
    grid-template-columns: 1fr auto 1fr;
    height: var(--SuperHub-top-nav-height);
  }

  &-container_two-column {
    grid-template-columns: 1fr 1fr;
  }

  &-left {
    align-items: center;
    display: grid;
    gap: var(--xs);
    grid-template-columns: fit-content(240px) max-content
  }

  &-right {
    align-items: center;
    display: flex;
    gap: var(--xs);
    justify-content: flex-end;
  }

  &-help-button {
    background-color: rgba(255, 255, 255, 0.15);
    border-color: var(--color-border-default);
    height: 24px;
    padding: 0;
    transition: background var(--transition-fast);
    width: 24px;

    &:global(.Button):not(:disabled):hover {
      background-color: rgba(255, 255, 255, 0.20);
      color: var(--white);
    }
  }

  &-mode-toggle {
    @include dark-mode {
      // We need to specifically override the dark mode styles here
      --Segment-highlight-background: rgba(var(--blue-rgb), 75%);
      --Segment-highlight-border: transparent;
    }

    &_success {
      --Segment-highlight-background: rgba(var(--green-rgb), 0.80);
      --Segment-highlight-border: transparent;

      @include dark-mode {
        --Segment-highlight-background: rgba(var(--green-rgb), 0.80);
        --Segment-highlight-border: transparent;
      }
    }
  }
}