.CreateVersionForm {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border-default);
  padding: var(--sm) 0;

  &-fields {
    align-items: flex-start;
    display: grid;
    gap: var(--sm);
    grid-template-columns: 1fr 0fr 1fr;
    padding: 0 var(--sm);

    &-arrow {
      margin-top: 30px;
    }

    &-name-wrapper {
      --Input-prefix-width: 20px;

      > :global(.InputParent-prefix) {
        justify-content: flex-end;
      }
    }
  }

  &-divider {
    border: 0;
    border-bottom: 1px solid var(--color-border-default);
    margin: 0;
  }

  &-cta {
    box-sizing: border-box;
    padding: 0 var(--sm);

    &-description {
      color: var(--color-text-minimum);
      font-size: 12px;
      font-weight: var(--font-weight-normal);
      line-height: 1.2;
      padding: 0 var(--sm);
      text-align: center;
    }
  }

  &-toast {
    > a {
      &, &:hover {
        color: var(--color-text-muted);
        text-decoration: underline;
      }
    }
  }
}

